import { Typography } from '@destination/components';

export interface ILegendItem {
  isToggled?: boolean;
  color?: string;
  label: string;
  style?: object;
  onClick?: () => void;
}

export const LegendItem = ({
  isToggled = true,
  color,
  label,
  style,
  onClick
}: ILegendItem) => {
  const containerClasses = ['group flex items-center gap-1'];
  const legendColorClasses = ['h-1.5 w-1.5 rounded-full'];
  const labelClasses = [];

  if (!isToggled) {
    labelClasses.push('text-gray-400');
    legendColorClasses.push('bg-gray-300');
  } else if (color) {
    legendColorClasses.push(color);
  }

  if (onClick) {
    labelClasses.push('group-hover:text-gray-600');
  }

  const content = (
    <>
      <div
        data-testid="legend-color"
        className={legendColorClasses.join(' ')}
        style={style}
      ></div>
      <Typography className={labelClasses.join(' ')} variant="smallText">
        {label}
      </Typography>
    </>
  );

  return onClick ? (
    <button className={containerClasses.join(' ')} onClick={onClick}>
      {content}
    </button>
  ) : (
    <div className={containerClasses.join(' ')}>{content}</div>
  );
};
