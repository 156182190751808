import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';

import useDocument from '@api/documents/use-document';
import { HMICustomizationEdit } from '@components/hmi/edit/HMICustomizationEdit';
import { HMICustomizationEditError } from '@components/hmi/edit/HMICustomizationEditError';
import { HMICustomizationEditLoading } from '@components/hmi/edit/HMICustomizationEditLoading';

export const Edit = () => {
  const { t } = usePortalTranslation('hmi');
  const { customizationId } = useParams();

  const { document, isLoading, isError, mutate } = useDocument(customizationId);

  const pageTitle = t('edit_customization.technical.title', {
    name: document ? document.name : ''
  });

  if (isLoading) {
    return <HMICustomizationEditLoading />;
  }

  if (isError) {
    return <HMICustomizationEditError pageTitle={pageTitle} mutate={mutate} />;
  }

  if (document === undefined) {
    return null;
  }

  const errorNotification = t('edit_customization.technical.failed_to_update', {
    name: document.name
  });
  const successNotification = t(
    'edit_customization.technical.succeeded_to_update',
    { name: document.name }
  );

  return (
    <HMICustomizationEdit
      hmiDocument={document}
      pageTitle={pageTitle}
      errorNotification={errorNotification}
      successNotification={successNotification}
    />
  );
};
