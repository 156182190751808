import { ReactNode, useLayoutEffect, useMemo } from 'react';

import {
  TOverloadedFunction,
  usePortalTranslation
} from '@hooks/use-portal-translation';
import { getTime } from 'date-fns';
import {
  CartesianGrid,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { ComposedGraph } from '@components/charts/ComposedGraph';
import { CustomCursor } from '@components/charts/CustomCursor';
import { CustomTooltip } from '@components/charts/CustomTooltip';
import { CustomYAxisLabel } from '@components/charts/CustomYAxisLabel';
import { CustomYAxisTick } from '@components/charts/CustomYAxisTick';
import { useInsightsContext } from '@context/InsightsContext';
import { useYAxisTickContext } from '@context/YAxisTickContext';
import { formatDate, timeFormat } from '@utils/date-utils';
import { getTicksForInterval } from '@utils/insights-utils';

import { LoadingSkeleton } from '@destination/components';

import { DataKey, VisibilityState } from './ChargerCurrentDelivered';

interface IEvsePhaseCurrentChart {
  isLoading?: boolean;
  data: { timestamp: number; l1: number; l2: number; l3: number }[];
  visibleLines?: VisibilityState;
  additionalLines?: ReactNode;
  height?: string;
  width?: string;
  startTime?: string;
  endTime?: string;
}

export const EvsePhaseCurrentChart = ({
  isLoading = false,
  data,
  additionalLines,
  height = '100%',
  width = '100%',
  startTime,
  endTime,
  visibleLines = {
    l1: true,
    l2: true,
    l3: true,
    soc: true
  }
}: IEvsePhaseCurrentChart) => {
  const { t } = usePortalTranslation();
  const { clearCollection } = useYAxisTickContext();
  const { range, handleResetZoom, handleZoom } = useInsightsContext(
    startTime ? new Date(startTime) : undefined,
    endTime ? new Date(endTime) : undefined
  );

  useLayoutEffect(() => {
    // Clear the collection every time the data changes so that the Y-axis min/max values are recalculated
    clearCollection();
  }, [data, clearCollection]);

  const axis = useMemo(() => {
    return getAxis([getTime(range[0]), getTime(range[1])]);
  }, [range]);

  return (
    <div
      className="relative flex-auto"
      data-testid="evse-current-delivered-graph"
    >
      {isLoading && (
        <div className="absolute inset-0 z-50 mb-[33px] ml-[46px] mr-[52px] mt-1 bg-white">
          <LoadingSkeleton className="!h-full rounded-[10px]" height={0} />
        </div>
      )}
      <ResponsiveContainer
        width={width}
        height={height}
        className="[&>div>div>svg]:overflow-visible"
      >
        <ComposedGraph onZoom={handleZoom} onResetZoom={handleResetZoom}>
          <CartesianGrid strokeDasharray="5 5" stroke="#EBEBEB" />
          {axis}
          {socYAxis}
          <Tooltip
            allowEscapeViewBox={{ x: true, y: true }}
            offset={0}
            isAnimationActive={false}
            content={<CustomTooltip />}
            cursor={data.length ? <CustomCursor /> : <></>}
          />
          {lines(data, t).filter(
            line => visibleLines[line.props.dataKey as DataKey]
          )}
          {socLines(data).filter(
            line => visibleLines[line.props.dataKey as DataKey]
          )}
          {additionalLines}
        </ComposedGraph>
      </ResponsiveContainer>
    </div>
  );
};

const getAxis = (
  domain: [number, number],
  currentYAxisPosition?: 'left' | 'right'
) => [
  <XAxis
    key={1}
    dataKey="timestamp"
    type="number"
    scale="time"
    domain={domain}
    padding={{ left: 33, right: 33 }}
    stroke={'#EBEBEB'}
    tickFormatter={tick => formatDate(tick, timeFormat)}
    tick={{ fontSize: 12, fill: '#9F9F9F', fontFamily: 'ABBvoice' }}
    ticks={getTicksForInterval(domain)}
    tickLine={false}
  />,
  <YAxis
    key={2}
    stroke={'#EBEBEB'}
    padding={{ top: 16 }}
    tickFormatter={value => `${value} A`}
    tick={<CustomYAxisTick />}
    tickLine={false}
    width={44}
    orientation={currentYAxisPosition}
  />
];

const lines = (
  data: { timestamp: number; l1: number; l2: number; l3: number }[],
  t: TOverloadedFunction
) =>
  [
    {
      dataKey: 'l1',
      name: `${t('literals.consumption')} - p1`,
      stroke: '#000000'
    },
    {
      dataKey: 'l2',
      name: `${t('literals.consumption')} - p2`,
      stroke: '#77A3FC'
    },
    {
      dataKey: 'l3',
      name: `${t('literals.consumption')} - p3`,
      stroke: '#FF9F8C'
    }
  ].map((line, index) => (
    <Line
      data={data}
      key={index + 1}
      {...line}
      unit="A"
      type="monotone"
      isAnimationActive={false}
      dot={false}
      activeDot={false}
      connectNulls={true}
    />
  ));

const socYAxis = (
  <YAxis
    key="socYAxis"
    label={<CustomYAxisLabel value="SoC" />}
    domain={[0, 100]}
    orientation="right"
    padding={{ top: 16 }}
    stroke={'#EBEBEB'}
    tickFormatter={value => `${value}%`}
    tick={<CustomYAxisTick />}
    tickLine={false}
    width={49}
  />
);

const socLines = (
  data: { timestamp: number; l1: number; l2: number; l3: number }[]
) =>
  [{ dataKey: 'soc', name: 'State of Charge', stroke: '#E64997' }].map(line => (
    <Line
      data={data}
      key="soc"
      {...line}
      unit="%"
      type="monotone"
      isAnimationActive={false}
      dot={false}
      activeDot={false}
      connectNulls={true}
    />
  ));
