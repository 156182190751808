import useSWR from 'swr';

import { DOCUMENTS_PATH } from '@api/paths';

import { Charger } from '@omnis-hmi-types';

export default function useHMIChargers(search?: string) {
  const params = new URLSearchParams();
  let url = `${DOCUMENTS_PATH}/chargers`;

  if (search && search.length > 0) {
    params.append('search', search);
    url = url + `?${params.toString()}`;
  }

  const { data, error, isLoading, mutate } = useSWR<Charger[]>(url);
  return {
    chargers: data,
    isLoading,
    isError: Boolean(error),
    mutate
  };
}
