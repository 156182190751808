import { usePortalTranslation } from '@hooks/use-portal-translation';

import { CouldNotLoadData } from '@components/ui/CouldNotLoadData';

import { Grid, TableLoading, Typography } from '@destination/components';

import { HmiDocument } from '@omnis-hmi-types';

import { HMICustomizationTableRow } from './HMICustomizationTableRow';

export interface IHMICustomizationTable {
  documents?: HmiDocument[];
  isLoading: boolean;
  isError: boolean;
}

export const HMICustomizationTable = ({
  documents,
  isLoading,
  isError
}: IHMICustomizationTable) => {
  const { t, t_generic } = usePortalTranslation('hmi');

  const noFilesMessage = t('labels.no_files_yet');

  return (
    <>
      <div className="overflow-auto">
        <Grid
          className="grid-cols-[1fr_1fr_1fr_1fr_168px]"
          data-testid="customization-table-title"
        >
          <Grid.HeadCell>{t_generic('literals.name')}</Grid.HeadCell>
          <Grid.HeadCell>{t_generic('literals.file')}</Grid.HeadCell>
          <Grid.HeadCell>{t_generic('literals.timestamp')}</Grid.HeadCell>
          {/* <Grid.HeadCell>{t_generic('literals.site_other')}</Grid.HeadCell> */}
          <Grid.HeadCell>{t_generic('literals.charger_other')}</Grid.HeadCell>
          <Grid.HeadCell>{t_generic('literals.action_other')}</Grid.HeadCell>
          {documents &&
            documents?.length > 0 &&
            documents.map((document, index) => (
              <HMICustomizationTableRow
                key={document.documentId}
                index={index}
                document={document}
                onDelete={() => null}
                onDownload={() => null}
                data-testid={`customization-table-row-${index}`}
              />
            ))}
        </Grid>
        {isLoading && <TableLoading className="mt-3" />}
      </div>
      {documents?.length === 0 && (
        <div className="mx-auto my-auto w-[275px] text-center">
          <Typography>{noFilesMessage}</Typography>
        </div>
      )}
      {isError && (
        <div className="mx-auto my-auto w-[275px] text-center">
          <CouldNotLoadData />
        </div>
      )}
    </>
  );
};
