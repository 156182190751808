import { DOCUMENTS_PATH } from '@api/paths';
import { modifier } from '@utils/swr-utils';

import { UpdateDocument } from '@omnis-hmi-types';

export default function patchDocument(
  documentId: string,
  serialNumbers: string[]
) {
  const url = `${DOCUMENTS_PATH}/${documentId}`;
  const payload: UpdateDocument = {
    serialNumbers
  };
  return modifier(url, 'PATCH', payload);
}
