import { usePortalTranslation } from '@hooks/use-portal-translation';
import { Link } from 'react-router-dom';

import { Button } from '@destination/components';

export interface IHMICustomizationEditActions {
  isDisabled: boolean;
  onSave: () => void;
}

export const HMICustomizationEditActions = ({
  isDisabled,
  onSave
}: IHMICustomizationEditActions) => {
  const { t } = usePortalTranslation();

  return (
    <div className="flex justify-center gap-6">
      <Link to="/hmi-settings/charger-customization">
        <Button
          variant="ghost"
          disabled={isDisabled}
          data-testid="cancel-button"
        >
          {t('literals.cancel', { titleize: true })}
        </Button>
      </Link>
      <Button
        variant="primary"
        disabled={isDisabled}
        data-testid="upload-button"
        onClick={onSave}
      >
        {t('buttons.save', { titleize: true, entity: '' })}
      </Button>
    </div>
  );
};
