import { useLayoutEffect, useMemo, useState } from 'react';

import {
  TOverloadedFunction,
  usePortalTranslation
} from '@hooks/use-portal-translation';
import { getTime } from 'date-fns';
import { CartesianGrid, Line, Tooltip, XAxis, YAxis } from 'recharts';

import { ComposedGraph } from '@components/charts/ComposedGraph';
import { CustomCursor } from '@components/charts/CustomCursor';
import { CustomTooltip } from '@components/charts/CustomTooltip';
import { CustomYAxisTick } from '@components/charts/CustomYAxisTick';
import { useInsightsContext } from '@context/InsightsContext';
import { useYAxisTickContext } from '@context/YAxisTickContext';
import { formatDate, timeFormat } from '@utils/date-utils';
import { getTicksForInterval } from '@utils/insights-utils';

import { LoadingSkeleton } from '@destination/components';

import { SiteChargerCurrentDeliveredTelemetry } from '@omnis-pulse-types';

import { CurrentDeliveredLegend } from './CurrentDeliveredLegend';

export type CurrentDeliveredData = Omit<
  SiteChargerCurrentDeliveredTelemetry,
  'timestamp'
> &
  { timestamp: number }[];

export interface ICurrentDelivered {
  data: CurrentDeliveredData;
  isLoading: boolean;
}

export type DataKey =
  | 'chargersConsumption.l1'
  | 'residualLoad.l1'
  | 'currentSum.l1'
  | 'chargersConsumption.l2'
  | 'residualLoad.l2'
  | 'currentSum.l2'
  | 'chargersConsumption.l3'
  | 'residualLoad.l3'
  | 'currentSum.l3'
  | 'gridLimit';
export type VisibilityState = Record<DataKey, boolean>;

export const CurrentDeliveredGraph = ({
  data,
  isLoading
}: ICurrentDelivered) => {
  const { t_generic } = usePortalTranslation('chargers');

  const [visibleLines, setVisibleLines] = useState<VisibilityState>({
    'chargersConsumption.l1': true,
    'residualLoad.l1': true,
    'currentSum.l1': true,
    'chargersConsumption.l2': true,
    'residualLoad.l2': true,
    'currentSum.l2': true,
    'chargersConsumption.l3': true,
    'residualLoad.l3': true,
    'currentSum.l3': true,
    gridLimit: true
  });

  const { clearCollection } = useYAxisTickContext();
  const { range, handleZoom, handleResetZoom } = useInsightsContext();

  useLayoutEffect(() => {
    // Clear the collection every time the data changes so that the Y-axis min/max values are recalculated
    clearCollection();
  }, [data, clearCollection]);

  const axis = useMemo(() => {
    return getAxis([getTime(range[0]), getTime(range[1])]);
  }, [range]);

  return (
    <div className="flex h-[427px] flex-col">
      <div className="relative flex-auto" data-testid="current-delivered-graph">
        {isLoading && (
          <div className="absolute inset-0 z-50 mb-[33px] ml-[94px] mr-1 mt-1 bg-white">
            <LoadingSkeleton className="!h-full rounded-[10px]" height={0} />
          </div>
        )}
        <ComposedGraph
          onZoom={handleZoom}
          onResetZoom={handleResetZoom}
          hideCartesianGrid
        >
          <CartesianGrid strokeDasharray="5 5" stroke="#EBEBEB" />
          {axis}
          <Tooltip
            allowEscapeViewBox={{ x: true, y: true }}
            offset={0}
            isAnimationActive={false}
            content={<CustomTooltip />}
            cursor={data.length ? <CustomCursor /> : <></>}
          />
          {lines(data, t_generic).filter(
            line => visibleLines[line.props.dataKey as DataKey]
          )}
        </ComposedGraph>
      </div>
      <div className="flex-initial">
        <CurrentDeliveredLegend
          visibleLines={visibleLines}
          setVisibleLines={setVisibleLines}
        />
      </div>
    </div>
  );
};

const getAxis = (domain: [number, number]) => [
  <XAxis
    key={1}
    dataKey="timestamp"
    type="number"
    scale="time"
    domain={domain}
    padding={{ left: 33, right: 33 }}
    stroke={'#EBEBEB'}
    tickFormatter={tick => formatDate(tick, timeFormat)}
    tick={{ fontSize: 12, fill: '#9F9F9F', fontFamily: 'ABBvoice' }}
    ticks={getTicksForInterval(domain)}
    tickLine={false}
  />,
  <YAxis
    key={2}
    stroke={'#EBEBEB'}
    padding={{ top: 16 }}
    tickFormatter={value => `${value} A`}
    tick={<CustomYAxisTick />}
    tickLine={false}
    width={92}
  />
];

// prettier-ignore
const lines = (data: CurrentDeliveredData, t_generic: TOverloadedFunction) => [
  { dataKey: "chargersConsumption.l1", name: `${t_generic('labels.entity_consumption', { titleize: true, entity: t_generic("literals.charger_other") })} - p1`, stroke: "#000000" },
  { dataKey: "residualLoad.l1", name: `${t_generic('labels.entity_load', { entity: t_generic('literals.residual'), titleize: true })} - p1`, stroke: "#686868" },
  { dataKey: "currentSum.l1", name: `${t_generic('literals.sum', { titleize: true })} - p1`, stroke: "#D6C918" },
  { dataKey: "chargersConsumption.l2", name: `${t_generic('labels.entity_consumption', { titleize: true, entity: t_generic("literals.charger_other") })} - p2`, stroke: "#77A3FC" },
  { dataKey: "residualLoad.l2", name: `${t_generic('labels.entity_load', { entity: t_generic('literals.residual'), titleize: true })} - p2`, stroke: "#7ED6C6" },
  { dataKey: "currentSum.l2", name: `${t_generic('literals.sum', { titleize: true })} - p2`, stroke: "#2A35FF" },
  { dataKey: "chargersConsumption.l3", name: `${t_generic('labels.entity_consumption', { titleize: true, entity: t_generic("literals.charger_other") })} - p3`, stroke: "#FF9F8C" },
  { dataKey: "residualLoad.l3", name: `${t_generic('labels.entity_load', { entity: t_generic('literals.residual'), titleize: true })} - p3`, stroke: "#E64997" },
  { dataKey: "currentSum.l3", name: `${t_generic('literals.sum', { titleize: true })} - p3`, stroke: "#C27121" },
  { dataKey: "gridLimit", name: t_generic('labels.entity_limit', { titleize: true, entity: t_generic('literals.grid') }), stroke: "#00ACEC" }
].map((line, index) => (
  <Line
    data={data}
    key={index + 1}
    {...line}
    unit="A"
    type="stepAfter"
    isAnimationActive={false}
    dot={false}
    activeDot={false}
    connectNulls={true}
  />
));
