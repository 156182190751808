import { usePortalTranslation } from '@hooks/use-portal-translation';

import useHMIChargers from '@api/documents/use-hmi-chargers';

import {
  DataError,
  Table,
  TableDataCell,
  TableHeadCell
} from '@destination/components';

export interface IHMICustomizationDrawerChargersTable {
  serialNumbers: string[];
}

export const HMICustomizationDrawerChargersTable = ({
  serialNumbers
}: IHMICustomizationDrawerChargersTable) => {
  const { t } = usePortalTranslation();

  const { chargers, isLoading, isError, mutate } = useHMIChargers();

  const renderContent = () => {
    if (isError) {
      return (
        <tr>
          <TableDataCell colSpan={3}>
            <div className="mt-2">
              <DataError message="" onRefresh={() => mutate(undefined, true)} />
            </div>
          </TableDataCell>
        </tr>
      );
    }

    if (chargers === undefined) {
      return null;
    }

    const appliedChargers = chargers.filter(charger =>
      serialNumbers.includes(charger.serialNumber)
    );

    if (appliedChargers.length === 0) {
      return (
        <tr>
          <TableDataCell colSpan={3} className="text-center">
            {t('labels.entity_not_found', {
              entity: t('literals.charger_other')
            })}
          </TableDataCell>
        </tr>
      );
    }

    return appliedChargers.map(({ serialNumber, name, model }, index) => (
      <tr
        key={serialNumber}
        className={index % 2 !== 0 ? 'tw-bg-[#FAFAFA]' : ''}
      >
        <TableDataCell
          className="truncate border-b-[1px] border-[#DBDBDB]"
          title={name}
        >
          {name}
        </TableDataCell>
        <TableDataCell
          className="truncate border-b-[1px] border-[#DBDBDB]"
          title={serialNumber}
        >
          {serialNumber}
        </TableDataCell>
        <TableDataCell
          className="truncate border-b-[1px] border-[#DBDBDB]"
          title={model}
        >
          {model}
        </TableDataCell>
      </tr>
    ));
  };

  return (
    <Table
      className="w-full table-fixed border-separate border-spacing-y-0"
      isLoading={isLoading}
    >
      <thead className="sticky top-0 z-10 bg-white">
        <tr className="leading-[0px]">
          <TableHeadCell className="text-nowrap">
            {t('literals.charger')}
          </TableHeadCell>
          <TableHeadCell className="text-nowrap">
            {t('literals.serial_number')}
          </TableHeadCell>
          <TableHeadCell className="text-nowrap">
            {t('literals.model')}
          </TableHeadCell>
        </tr>
      </thead>
      <tbody>{renderContent()}</tbody>
    </Table>
  );
};
