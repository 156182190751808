import useSWR from 'swr';

import { DOCUMENTS_PATH } from '@api/paths';

import { DocumentType, PaginatedDocument } from '@omnis-hmi-types';

interface IUseDocuments {
  documentType: DocumentType;
  pageSize?: string;
}

export default function useDocuments({
  documentType,
  pageSize
}: IUseDocuments) {
  const params = new URLSearchParams();

  if (documentType) {
    params.append('type', documentType);
  }

  if (pageSize) {
    params.append('pageSize', pageSize);
    params.append('pageOffset', '0');
  }

  const url = `${DOCUMENTS_PATH}?${params.toString()}`;
  const { data, error, isLoading, mutate } = useSWR<PaginatedDocument>(url);

  return {
    documents: data?.data,
    isLoading,
    isError: Boolean(error),
    mutate
  };
}
