import { useState } from 'react';

import { useZoomChart } from '@hooks/use-zoom-chart';
import { getTime } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Line, Tooltip, XAxis, YAxis } from 'recharts';

import { ComposedGraph } from '@components/charts/ComposedGraph';
import { LegendItem } from '@components/charts/LegendItem';
import { generateXAxisTicks } from '@utils/chart-utils';
import { formatDate, timeFormat } from '@utils/date-utils';

import { CurrentTelemetry } from '@omnis-pulse-types';

import { RenderTooltip } from '../insights/GraphTooltip';

export interface ISiteOverviewChart {
  data: CurrentTelemetry;
}

type DataKey = 'currentL1' | 'currentL2' | 'currentL3' | 'gridLimit';
type VisibilityState = Record<DataKey, boolean>;

export const SiteOverviewChart = ({ data }: ISiteOverviewChart) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'sites.site.insights.graph'
  });

  const [visibleLines, setVisibleLines] = useState<VisibilityState>({
    currentL1: true,
    currentL2: true,
    currentL3: true,
    gridLimit: true
  });

  const { left, right, handleZoom, handleResetZoom } = useZoomChart();

  const handleToggleLine = (key: string) => {
    setVisibleLines(prev => ({
      ...prev,
      [key]: !prev[key as DataKey]
    }));
  };

  const seriesOfGridLimits = (data.maxCurrent || [])
    .filter(entry => entry.timestamp)
    .map(entry => ({
      gridLimit: entry.value,
      timestamp: getTime(new Date(entry.timestamp))
    }));

  const seriesOfConsumptionValues = (data.current || [])
    .filter(entry => entry.timestamp)
    .map(entry => ({
      ...entry,
      timestamp: getTime(new Date(entry.timestamp))
    }));

  return (
    <div className="flex h-full flex-col">
      <div className="relative flex-auto">
        <ComposedGraph onZoom={handleZoom} onResetZoom={handleResetZoom}>
          <Tooltip content={<RenderTooltip />} />
          <XAxis
            dataKey="timestamp"
            tickLine={false}
            tick={{ fill: '#9F9F9F', fontSize: 8 }}
            stroke="#ebebeb"
            interval="preserveStartEnd"
            tickFormatter={tick => formatDate(tick, timeFormat)}
            scale="time"
            type="number"
            domain={left && right ? [left, right] : ['auto', 'auto']}
            ticks={generateXAxisTicks()}
          />
          <YAxis
            unit=" A"
            orientation="right"
            tickLine={false}
            tick={{ fill: '#9F9F9F', fontSize: 8 }}
            stroke="#ebebeb"
            type="number"
            width={45}
          />
          <Line
            type="linear"
            dataKey="currentL1"
            stroke="black"
            dot={false}
            connectNulls={true}
            data={seriesOfConsumptionValues}
            hide={visibleLines['currentL1'] === false}
            isAnimationActive={false}
          />
          <Line
            type="linear"
            dataKey="currentL2"
            stroke="#2A35FF"
            dot={false}
            connectNulls={true}
            data={seriesOfConsumptionValues}
            hide={visibleLines['currentL2'] === false}
            isAnimationActive={false}
          />
          <Line
            type="linear"
            dataKey="currentL3"
            stroke="#E64997"
            dot={false}
            connectNulls={true}
            data={seriesOfConsumptionValues}
            hide={visibleLines['currentL3'] === false}
            isAnimationActive={false}
          />
          {seriesOfGridLimits.length > 0 && (
            <Line
              type="stepAfter"
              dataKey="gridLimit"
              stroke="#00ACEC"
              dot={false}
              connectNulls={true}
              data={seriesOfGridLimits}
              hide={visibleLines['gridLimit'] === false}
              isAnimationActive={false}
            />
          )}
        </ComposedGraph>
      </div>
      <div className="flex-initial">
        <div className="ml-1.5 flex gap-6">
          {data.maxCurrent && data.maxCurrent.length > 0 && (
            <LegendItem
              isToggled={visibleLines['gridLimit']}
              label={t('gridLimit')}
              color="bg-[#00ACEC]"
              onClick={() => handleToggleLine('gridLimit')}
            />
          )}
          <LegendItem
            isToggled={visibleLines['currentL1']}
            label={t('currentL1')}
            color="bg-black"
            onClick={() => handleToggleLine('currentL1')}
          />
          <LegendItem
            isToggled={visibleLines['currentL2']}
            label={t('currentL2')}
            color="bg-[#2A35FF]"
            onClick={() => handleToggleLine('currentL2')}
          />
          <LegendItem
            isToggled={visibleLines['currentL3']}
            label={t('currentL3')}
            color="bg-[#E64997]"
            onClick={() => handleToggleLine('currentL3')}
          />
        </div>
      </div>
    </div>
  );
};
