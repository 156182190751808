import useSWR from 'swr';

import { DOCUMENTS_PATH } from '@api/paths';

import { HmiDocument } from '@omnis-hmi-types';

export default function useDocument(documentId?: string) {
  const url = `${DOCUMENTS_PATH}/${documentId}`;
  const { data, error, isLoading, mutate } = useSWR<HmiDocument>(
    documentId ? url : null
  );

  return {
    document: data,
    isLoading,
    isError: Boolean(error),
    mutate
  };
}
