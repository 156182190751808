import {
  rand,
  randFileName,
  randNumber,
  randPastDate,
  randSemver,
  randUuid
} from '@ngneat/falso';

import { makeArray } from '@utils/faker-utils';

import { DocumentType, HmiDocument } from '@omnis-hmi-types';

export class HmiDocumentMockFactory {
  static createDocument(data?: Partial<HmiDocument>): HmiDocument {
    const filename = randFileName();

    return {
      documentId: randUuid(),
      name: filename.split('.')[0],
      filename,
      version: randSemver(),
      createdAt: randPastDate().toISOString(),
      documentType: rand([
        DocumentType.INSTANCE_GRAPH,
        DocumentType.CUSTOMER_PREFERENCES,
        DocumentType.PROPERTY_OVERRIDES
      ]),
      serialNumbers: makeArray(randNumber({ min: 1, max: 100 }), () =>
        randUuid()
      ),
      ...data
    };
  }

  static createDocuments(
    numberOfDocuments: number,
    data?: Partial<HmiDocument>
  ) {
    return makeArray(numberOfDocuments, () => this.createDocument(data));
  }
}
