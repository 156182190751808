import { usePortalTranslation } from '@hooks/use-portal-translation';
import { Link, useNavigate } from 'react-router-dom';

import createDocument from '@api/documents/create-document';
import { useUploadContext } from '@context/hmi/UploadContext';

import { Button, useAlert } from '@destination/components';

import { DocumentType } from '@omnis-hmi-types';

interface IHMICustomizationUploadActions {
  documentType: DocumentType;
  errorNotification: string;
  successNotification: string;
}

export const HMICustomizationUploadActions = ({
  documentType,
  errorNotification,
  successNotification
}: IHMICustomizationUploadActions) => {
  const { t } = usePortalTranslation();
  const { file, name, selected, isFileValid, isProcessing, setIsProcessing } =
    useUploadContext();
  const { notify, notifyError } = useAlert();

  const navigate = useNavigate();

  const handleUpload = async () => {
    if (file) {
      setIsProcessing(true);
      const { error } = await createDocument({
        name: name.length ? name : file.name,
        version: '1.0.0',
        type: documentType,
        file,
        serialNumbers: Array.from(selected)
      });

      if (error) {
        notifyError({
          header: errorNotification,
          'data-testid': 'upload-error-alert'
        });
      } else {
        notify({
          variant: 'success',
          header: successNotification,
          'data-testid': 'upload-success-alert'
        });
        navigate('/hmi-settings/charger-customization');
      }

      setIsProcessing(false);
    }
  };

  return (
    <div className="flex justify-center gap-6">
      <Link to="/hmi-settings/charger-customization">
        <Button
          variant="ghost"
          disabled={isProcessing}
          data-testid="cancel-button"
        >
          {t('literals.cancel', { titleize: true })}
        </Button>
      </Link>
      <Button
        variant="primary"
        disabled={!(file && isFileValid)}
        data-testid="upload-button"
        isLoading={isProcessing}
        onClick={handleUpload}
      >
        {t('buttons.upload', { titleize: true, entity: '' })}
      </Button>
    </div>
  );
};
