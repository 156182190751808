import { usePortalTranslation } from '@hooks/use-portal-translation';

import { HMICustomizationDrawer } from '@components/hmi/drawer/HMICustomizationDrawer';
import { HMICustomization } from '@components/hmi/HMICustomization';

import { DocumentType } from '@omnis-hmi-types';

export const Technical = () => {
  const { t } = usePortalTranslation('hmi');

  const title = t('customizations.technical.title');
  const description = t('customizations.technical.description');

  return (
    <>
      <HMICustomization
        documentType={DocumentType.PROPERTY_OVERRIDES}
        title={title}
        description={description}
        uploadLink="upload"
        data-testid="technical-settings"
      />
      <HMICustomizationDrawer closeDrawerLink="/hmi-settings/charger-customization/technical" />
    </>
  );
};
