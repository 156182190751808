import { Dispatch, SetStateAction } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import {
  Checkbox,
  Table,
  TableDataCell,
  TableHeadCell,
  Typography
} from '@destination/components';

import { Charger } from '@omnis-hmi-types';

import { HMICustomizationChargersTableRow } from './HMICustomizationChargersTableRow';

export interface IHMICustomizationChargersTable {
  chargers: Charger[];
  filteredChargers: Charger[];
  isLoading: boolean;
  isDisabled: boolean;
  searchTerm: string;
  selected: Set<string>;
  setSelected: Dispatch<SetStateAction<Set<string>>>;
}

export const HMICustomizationChargersTable = ({
  chargers,
  filteredChargers,
  isLoading,
  isDisabled,
  searchTerm,
  selected,
  setSelected
}: IHMICustomizationChargersTable) => {
  const { t } = usePortalTranslation();

  const handleToggleAll = () => {
    if (selected.size === chargers.length) {
      setSelected(new Set());
    } else {
      setSelected(new Set(chargers.map(charger => charger.serialNumber)));
    }
  };

  const handleToggle = (serialNumber: string) => {
    setSelected(previousValue => {
      const newSelected = new Set(previousValue);
      if (newSelected.has(serialNumber)) {
        newSelected.delete(serialNumber);
      } else {
        newSelected.add(serialNumber);
      }
      return newSelected;
    });
  };

  return (
    <Table
      className="w-full table-fixed border-separate border-spacing-y-0"
      isLoading={isLoading}
    >
      <thead className="sticky top-0 z-10 bg-white">
        <tr className="leading-[0px]">
          <TableHeadCell className="text-nowrap !pb-2 !pl-3">
            <div
              className={`flex gap-2 [&>input]:!border-[1px] ${chargers.length !== selected.size && '[&>input]:!bg-[#F5F5F5]'}`}
            >
              <Checkbox
                data-testid="header-checkbox"
                label={undefined}
                checked={!isLoading && chargers.length === selected.size}
                disabled={isDisabled || isLoading}
                onChange={handleToggleAll}
              />
              {`${t('literals.charger')} (${chargers.length})`}
            </div>
          </TableHeadCell>
          <TableHeadCell className="text-nowrap">
            {t('literals.serial_number')}
          </TableHeadCell>
          <TableHeadCell className="text-nowrap">
            {t('literals.model')}
          </TableHeadCell>
        </tr>
      </thead>
      <tbody>
        {!isLoading && filteredChargers.length === 0 ? (
          <tr>
            <TableDataCell colSpan={3} className="text-center">
              <Typography>
                {t('labels.search_no_found', { searchTerm })}
              </Typography>
            </TableDataCell>
          </tr>
        ) : (
          <>
            {filteredChargers.map((charger, index) => (
              <tr
                key={charger.id}
                className={index % 2 !== 0 ? 'tw-bg-[#FAFAFA]' : ''}
              >
                <HMICustomizationChargersTableRow
                  isChecked={selected.has(charger.serialNumber)}
                  isDisabled={isDisabled}
                  searchTerm={searchTerm}
                  name={charger.name}
                  serial={charger.serialNumber}
                  model={charger.model}
                  onToggle={() => handleToggle(charger.serialNumber)}
                />
              </tr>
            ))}
          </>
        )}
      </tbody>
    </Table>
  );
};
