import { Link } from 'react-router-dom';

import DeleteIcon from '@icons/delete_16.svg';
import DownloadIcon from '@icons/download_16.svg';
import EditIcon from '@icons/edit_16.svg';
import { dateTimeFormat, formatDate } from '@utils/date-utils';
import { getEditLink } from '@utils/hmi-utils';

import { Grid, IconButton } from '@destination/components';

import { HmiDocument } from '@omnis-hmi-types';

export interface IHMICustomizationTableRow {
  index: number;
  document: HmiDocument;
  onDelete: () => void;
  onDownload: () => void;
}

export const HMICustomizationTableRow = ({
  index,
  document,
  onDelete,
  onDownload
}: IHMICustomizationTableRow) => {
  // todo: Make sure the documentId and createdAt are strings
  const {
    documentId,
    documentType,
    name,
    filename,
    createdAt = '',
    serialNumbers = []
  } = document;

  return (
    <Grid.TableRow
      alternateRowColor={index % 2 === 0}
      data-testid={`customization-table-row-${index}`}
    >
      <Grid.DataCell data-testid={`customization-table-row-${index}-name`}>
        <Link
          to={documentId}
          className="break-all decoration-2 hover:font-bold hover:underline"
          data-text={name}
          title={name}
        >
          {name}
        </Link>
      </Grid.DataCell>
      <Grid.DataCell
        className="break-all"
        data-testid={`customization-table-row-${index}-filename`}
      >
        {filename}
      </Grid.DataCell>
      <Grid.DataCell
        className="text-nowrap"
        data-testid={`customization-table-row-${index}-createdAt`}
      >
        {formatDate(createdAt, dateTimeFormat)}
      </Grid.DataCell>
      {/* <Grid.DataCell>{''}</Grid.DataCell> */}
      <Grid.DataCell data-testid={`customization-table-row-${index}-chargers`}>
        {serialNumbers.length}
      </Grid.DataCell>
      <Grid.DataCell data-testid={`customization-table-row-${index}-actions`}>
        <Actions
          editLink={getEditLink(documentId, documentType)}
          onDelete={onDelete}
          onDownload={onDownload}
        />
      </Grid.DataCell>
    </Grid.TableRow>
  );
};

interface IActions {
  editLink: string;
  onDelete: () => void;
  onDownload: () => void;
}

const Actions = ({ editLink, onDelete, onDownload }: IActions) => {
  return (
    <div className="-my-[7px] flex gap-2">
      <div className="invisible">
        <IconButton data-testid="delete-icon" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </div>
      <div className="invisible">
        <IconButton data-testid="download-icon" onClick={onDownload}>
          <DownloadIcon />
        </IconButton>
      </div>
      <Link to={editLink}>
        <IconButton data-testid="edit-icon">
          <EditIcon />
        </IconButton>
      </Link>
    </div>
  );
};
