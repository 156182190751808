import { usePortalTranslation } from '@hooks/use-portal-translation';

import FolderIcon from '@icons/folder_24.svg';

import { Card, LoadingSkeleton, Typography } from '@destination/components';

export const HMICustomizationEditLoading = () => {
  return (
    <>
      <div className="mb-6">
        <LoadingSkeleton isDarker className="w-60" height={30} />
      </div>
      <div className="mb-12 flex max-w-lg flex-col gap-6">
        <FileDetailsCard />
        <ApplyToChargersCard />
      </div>
    </>
  );
};

const FileDetailsCard = () => {
  const { t } = usePortalTranslation('hmi');
  return (
    <Card>
      <Typography variant="smallTitle" className="mb-[35px]">
        {t('labels.name_of_the_theme')}
      </Typography>
      <div className="mb-7 max-w-[342px]">
        <LoadingSkeleton height={40} />
      </div>
      <div className="ml-3 flex items-center gap-2">
        <FolderIcon className="text-[#BABABA]" />
        <LoadingSkeleton className="w-48" height={18} />
      </div>
    </Card>
  );
};

const ApplyToChargersCard = () => {
  const { t } = usePortalTranslation('hmi');
  return (
    <Card>
      <div className="my-2">
        <Typography variant="smallTitle" className="mb-4">
          {t('labels.apply_to_chargers')}
        </Typography>
        <div className="mb-4 max-w-[365px]">
          <LoadingSkeleton height={40} />
        </div>
      </div>
    </Card>
  );
};
