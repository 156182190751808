import { createBrowserRouter, Navigate } from 'react-router-dom';

import { AxiosInterceptor } from '@api/AxiosInterceptor';
import { RouteErrorFallback } from '@components/RouteErrorFallback';
import { InsightsProvider } from '@context/InsightsContext';
import { SiteDevicesProvider } from '@context/SiteDevicesContext';

import { AccessManagement } from './access-management/AccessManagement';
import { Account } from './account/Account';
import { PageNotFound } from './errors/PageNotFound';
import { UserAccountUnknown } from './errors/UserAccountUnknown';
import { ChargerCustomization } from './hmi-settings/charger-customization/ChargerCustomization';
import { Customer } from './hmi-settings/charger-customization/customer/Customer';
import { Edit as CustomerEdit } from './hmi-settings/charger-customization/customer/edit/Edit';
import { Upload as CustomerUpload } from './hmi-settings/charger-customization/customer/upload/Upload';
import { Edit as TechnicalEdit } from './hmi-settings/charger-customization/technical/edit/Edit';
// import { Language } from './hmi-settings/charger-customization/language/Language';
// import { Upload as LanguageUpload } from './hmi-settings/charger-customization/language/upload/Upload';
// import { Edit as LanguageEdit } from './hmi-settings/charger-customization/language/edit/Edit';
import { Technical } from './hmi-settings/charger-customization/technical/Technical';
import { Upload as TechnicalUpload } from './hmi-settings/charger-customization/technical/upload/Upload';
import { HMISettings } from './hmi-settings/HMISettings';
import { Notifications } from './notifications/Notifications';
import { Root } from './root';
import { Settings } from './settings/Settings';
import { Create as CreateSite } from './sites/create/Create';
import { Charger as SiteCharger } from './sites/site/devices/chargers/charger/Charger';
import { Logs as ChargerLogs } from './sites/site/devices/chargers/charger/logs/Logs';
import { SessionHistory } from './sites/site/devices/chargers/charger/session-history/SessionHistory';
import { Chargers as SiteChargers } from './sites/site/devices/chargers/Chargers';
import { Devices as SiteDevices } from './sites/site/devices/Devices';
import { Gateway } from './sites/site/devices/gateways/gateway/Gateway';
import { Gateways } from './sites/site/devices/gateways/Gateways';
import { GridMeter } from './sites/site/devices/grid-meters/grid-meter/GridMeter';
import { GridMeters } from './sites/site/devices/grid-meters/GridMeters';
import { Insights as SiteInsights } from './sites/site/insights/Insights';
import { Logs as SiteLogs } from './sites/site/logs/Logs';
import { Overview as SiteOverview } from './sites/site/overview/Overview';
import { Site } from './sites/site/Site';
import { SiteSettings } from './sites/site/site-settings/SiteSettings';
import { Sites } from './sites/Sites';

export const router = createBrowserRouter([
  { path: '/auth-response', element: <Navigate to="/sites" replace /> },
  { path: '/unauthorized', element: <UserAccountUnknown /> },
  {
    path: '/',
    element: (
      <AxiosInterceptor>
        <Root />
      </AxiosInterceptor>
    ),
    children: [
      {
        index: true, // <-- match on parent, i.e. "/"
        element: <Navigate to="/sites" replace /> // <-- redirect
      },
      {
        path: 'sites/:id/devices/chargers/:chargerId/session-history',
        element: <SessionHistory />,
        errorElement: <RouteErrorFallback />
      },
      {
        path: 'sites/:id/devices/chargers/:chargerId/logs',
        element: <ChargerLogs />,
        errorElement: <RouteErrorFallback />
      },
      {
        path: 'sites',
        element: <Sites />,
        errorElement: <RouteErrorFallback />
      },
      {
        path: 'sites/create',
        element: <CreateSite />,
        errorElement: <RouteErrorFallback />
      },
      {
        path: 'sites/:id',
        element: (
          <SiteDevicesProvider>
            <Site />
          </SiteDevicesProvider>
        ),
        children: [
          {
            path: '',
            element: <SiteOverview />,
            errorElement: <RouteErrorFallback />
          },
          {
            path: 'devices',
            element: <SiteDevices />,
            errorElement: <RouteErrorFallback />,
            children: [
              {
                index: true,
                element: <Navigate to="chargers" />,
                errorElement: <RouteErrorFallback />
              },
              {
                path: 'chargers',
                element: <SiteChargers />,
                errorElement: <RouteErrorFallback />,
                children: [
                  {
                    path: ':chargerId',
                    element: <SiteCharger />,
                    errorElement: <RouteErrorFallback />
                  }
                ]
              },
              {
                path: 'grid-meters',
                element: <GridMeters />,
                errorElement: <RouteErrorFallback />,
                children: [
                  {
                    path: ':gridMeterId',
                    element: <GridMeter />,
                    errorElement: <RouteErrorFallback />
                  }
                ]
              },
              {
                path: 'gateways',
                element: <Gateways />,
                errorElement: <RouteErrorFallback />,
                children: [
                  {
                    path: ':gatewayId',
                    element: <Gateway />,
                    errorElement: <RouteErrorFallback />
                  }
                ]
              }
            ]
          },
          {
            path: 'site-settings',
            element: <SiteSettings />,
            errorElement: <RouteErrorFallback />
          },
          {
            path: 'logs',
            element: <SiteLogs />,
            errorElement: <RouteErrorFallback />
          },
          {
            path: 'insights',
            element: (
              <InsightsProvider>
                <SiteInsights />
              </InsightsProvider>
            ),
            errorElement: <RouteErrorFallback />
          }
        ],
        errorElement: <RouteErrorFallback />
      },
      {
        path: 'access-management',
        element: <AccessManagement />,
        errorElement: <RouteErrorFallback />
      },
      {
        path: 'account',
        element: <Account />,
        errorElement: <RouteErrorFallback />
      },
      {
        path: 'notifications',
        element: <Notifications />,
        errorElement: <RouteErrorFallback />
      },
      {
        path: 'settings',
        element: <Settings />,
        errorElement: <RouteErrorFallback />
      },
      {
        path: 'hmi-settings',
        element: <HMISettings />,
        errorElement: <RouteErrorFallback />,
        children: [
          {
            index: true,
            element: <Navigate to="charger-customization" />,
            errorElement: <RouteErrorFallback />
          },
          {
            path: 'charger-customization/:customizationId?',
            element: <ChargerCustomization />,
            errorElement: <RouteErrorFallback />
          }
        ]
      },
      {
        path: 'hmi-settings/charger-customization/customer/:customizationId?',
        element: <Customer />,
        errorElement: <RouteErrorFallback />
      },
      {
        path: 'hmi-settings/charger-customization/customer/:customizationId?/edit',
        element: <CustomerEdit />,
        errorElement: <RouteErrorFallback />
      },
      {
        path: 'hmi-settings/charger-customization/customer/upload',
        element: <CustomerUpload />,
        errorElement: <RouteErrorFallback />
      },
      // {
      //   path: 'hmi-settings/charger-customization/language/:customizationId?',
      //   element: <Language />,
      //   errorElement: <RouteErrorFallback />
      // },
      // {
      //   path: 'hmi-settings/charger-customization/language/:customizationId?/edit',
      //   element: <LanguageEdit />,
      //   errorElement: <RouteErrorFallback />
      // },
      // {
      //   path: 'hmi-settings/charger-customization/language/upload',
      //   element: <LanguageUpload />,
      //   errorElement: <RouteErrorFallback />
      // },
      {
        path: 'hmi-settings/charger-customization/technical/:customizationId?',
        element: <Technical />,
        errorElement: <RouteErrorFallback />
      },
      {
        path: 'hmi-settings/charger-customization/technical/:customizationId?/edit',
        element: <TechnicalEdit />,
        errorElement: <RouteErrorFallback />
      },
      {
        path: 'hmi-settings/charger-customization/technical/upload',
        element: <TechnicalUpload />,
        errorElement: <RouteErrorFallback />
      },
      {
        path: '*',
        element: <PageNotFound />,
        errorElement: <RouteErrorFallback />
      }
    ]
  }
]);
