import { usePortalTranslation } from '@hooks/use-portal-translation';

import { BreadcrumbLink } from '@components/breadcrumbs/BreadcrumbLink';
import {
  generateHmiBreadcrumbs,
  generateSiteBreadcrumbs
} from '@utils/breadcrumb-utils';

import { Breadcrumbs, LoadingSkeleton } from '@destination/components';

export interface IBreadcrumbComponent {
  isLoading: boolean;
  pathname: string;
  siteName?: string;
  basePath: string;
}

export const BreadcrumbComponent = ({
  isLoading,
  pathname,
  siteName,
  basePath
}: IBreadcrumbComponent) => {
  const { t, t_generic } = usePortalTranslation('hmi');

  if (isLoading) {
    return (
      <div className={getClasses(0)}>
        <LoadingSkeleton isDarker height={16} className="mt-auto w-60" />
      </div>
    );
  }

  const chunks = pathname.substring(1).split('/');

  let breadcrumbs: { label: string; path: string }[] = [];

  if (basePath === '/hmi-settings') {
    breadcrumbs = generateHmiBreadcrumbs(chunks, t, t_generic);
  } else if (basePath === '/sites') {
    breadcrumbs = generateSiteBreadcrumbs(chunks, siteName, t_generic);
  }

  const nodes = breadcrumbs.map(({ label, path }) => ({
    id: label,
    content: <BreadcrumbLink label={label} path={path} />
  }));

  return (
    <div className={getClasses(breadcrumbs.length)}>
      <Breadcrumbs
        data-testid={`${basePath.substring(1)}-breadcrumbs`}
        breadcrumbs={nodes}
      />
    </div>
  );
};

const getClasses = (breadcrumbsLength: number) => {
  let classes = 'flex h-8 [&>nav]:mt-auto min-w-[403px]';

  if (breadcrumbsLength < 3) {
    classes +=
      ' [&>nav>ol>li:last-child>div]:w-full [&>nav>ol>li:last-child]:w-full [&>nav>ol>li:last-child]:truncate [&>nav>ol]:w-full [&>nav]:w-full';
  }

  return classes;
};
