import { Dispatch, SetStateAction, useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import useHMIChargers from '@api/documents/use-hmi-chargers';

import {
  Card,
  DataError,
  SearchInput,
  Typography
} from '@destination/components';

import { HMICustomizationChargersTable } from './HMICustomizationChargersTable';

export interface IHMICustomizationApplyToChargersCard {
  isFile: boolean;
  isDisabled: boolean;
  selected: Set<string>;
  setSelected: Dispatch<SetStateAction<Set<string>>>;
}

export const HMICustomizationApplyToChargersCard = ({
  isFile,
  isDisabled,
  selected,
  setSelected
}: IHMICustomizationApplyToChargersCard) => {
  const { t } = usePortalTranslation('hmi');

  const [searchTerm, setSearchTerm] = useState('');

  const {
    chargers,
    isLoading: isLoadingAll,
    isError: isErrorAll,
    mutate: mutateAll
  } = useHMIChargers();
  const {
    chargers: filteredChargers,
    isLoading: isLoadingFiltered,
    isError: isErrorFiltered,
    mutate: mutateFiltered
  } = useHMIChargers(searchTerm);

  const isLoading = isLoadingAll || isLoadingFiltered;
  const isError = isErrorAll || isErrorFiltered;

  const title = t('labels.apply_to_chargers');
  const searchPlaceholder = t(
    'labels.search_for_a_charger_name_or_serial_number'
  );

  const renderContent = () => {
    if (isFile === false) {
      return (
        <Typography>{t('labels.add_a_file_to_select_the_chargers')}</Typography>
      );
    }

    if (isError) {
      return (
        <DataError
          onRefresh={() => {
            mutateAll(undefined, true);
            mutateFiltered(undefined, true);
          }}
        />
      );
    }

    if (chargers && chargers.length === 0) {
      return (
        <Typography>
          {t('labels.there_are_no_compatible_chargers_found')}
        </Typography>
      );
    }

    return (
      <div>
        <div className="mb-4 max-w-[365px]">
          <SearchInput
            placeholder={searchPlaceholder}
            value={searchTerm}
            onChange={event => setSearchTerm(event.target.value)}
          />
        </div>
        <Typography className="mx-3 mb-3">
          {t('labels.chargers_amount_selected', { count: selected.size })}
        </Typography>
        <div className="max-h-[400px] overflow-y-auto">
          <HMICustomizationChargersTable
            chargers={chargers ?? []}
            filteredChargers={filteredChargers ?? []}
            isLoading={isLoading}
            isDisabled={isDisabled}
            searchTerm={searchTerm}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
      </div>
    );
  };

  return (
    <Card className="max-h-[626px]">
      <div className="my-2">
        {!isError && (
          <Typography variant="smallTitle" className={isFile ? 'mb-4' : 'mb-2'}>
            {title}
          </Typography>
        )}
        {renderContent()}
      </div>
    </Card>
  );
};
