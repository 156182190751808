import { usePortalTranslation } from '@hooks/use-portal-translation';
import { Link } from 'react-router-dom';

import useDocuments from '@api/documents/use-documents';
import { NavigationButton } from '@components/buttons/NavigationButton';

import { Button, Card, Typography } from '@destination/components';

import { DocumentType } from '@omnis-hmi-types';

import { HMICustomizationDownloadTemplate } from './HMICustomizationDownloadTemplate';
import { HMICustomizationTable } from './HMICustomizationTable';

interface IHMICustomization {
  documentType: DocumentType;
  title: string;
  description: string;
  uploadLink: string;
  seeAllLink?: string;
  'data-testid'?: string;
}

export const HMICustomization = ({
  documentType,
  title,
  description,
  uploadLink,
  seeAllLink,
  'data-testid': id
}: IHMICustomization) => {
  const { t } = usePortalTranslation();

  const { documents, isLoading, isError } = useDocuments({
    documentType,
    pageSize: seeAllLink ? '5' : undefined
  });

  const uploadFileLabel = t('buttons.upload', {
    entity: t('literals.file'),
    titleize: true
  });
  const buttonLabel = t('labels.see_all_entities', {
    entity: t('literals.file_other'),
    titleize: true
  });

  const cardClasses = ['-mx-4 flex flex-col pt-2 !shadow-none'];

  if (isError || documents?.length === 0) {
    cardClasses.push('h-[170px]');
  }

  return (
    <div data-testid={id}>
      <div className="mb-[5px] flex items-center justify-between">
        <Typography variant="sectionTitle">{title}</Typography>
        <div className="flex gap-6">
          <HMICustomizationDownloadTemplate documentType={documentType} />
          <Link to={uploadLink}>
            <Button variant="primary" data-testid="upload-button">
              {uploadFileLabel}
            </Button>
          </Link>
        </div>
      </div>
      <Typography className="mb-4">{description}</Typography>
      <Card className={cardClasses.join(' ')}>
        <HMICustomizationTable
          documents={documents}
          isLoading={isLoading}
          isError={isError}
        />
      </Card>
      {seeAllLink && documents?.length === 5 && (
        <div className="ml-auto w-fit">
          <NavigationButton
            className="mt-2 flex justify-end"
            label={buttonLabel}
            to={seeAllLink}
          />
        </div>
      )}
    </div>
  );
};
