import { usePortalTranslation } from '@hooks/use-portal-translation';

import { HMICustomizationUpload } from '@components/hmi/upload/HMICustomizationUpload';

import { DocumentType } from '@omnis-hmi-types';

export const Upload = () => {
  const { t } = usePortalTranslation('hmi');

  const pageTitle = t('upload_customization.customer.title');
  const pageDescription = t('upload_customization.customer.description');
  const cardTitle = t('upload_customization.customer.subtitle');
  const errorNotification = t('upload_customization.customer.failed_to_upload');
  const successNotification = t(
    'upload_customization.customer.succeeded_to_upload'
  );

  return (
    <HMICustomizationUpload
      documentType={DocumentType.CUSTOMER_PREFERENCES}
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      cardTitle={cardTitle}
      errorNotification={errorNotification}
      successNotification={successNotification}
    />
  );
};
