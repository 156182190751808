import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useNavigate, useParams } from 'react-router-dom';

import useDocument from '@api/documents/use-document';
import { getEditLink } from '@utils/hmi-utils';

import {
  DataError,
  Drawer,
  LoadingSkeleton,
  Typography
} from '@destination/components';

import { DocumentType, HmiDocument } from '@omnis-hmi-types';

import { HMICustomizationDrawerActions } from './HMICustomizationDrawerActions';
import { HMICustomizationDrawerChargersTable } from './HMICustomizationDrawerChargersTable';

interface IHMICustomizationDrawer {
  closeDrawerLink: string;
}

export const HMICustomizationDrawer = ({
  closeDrawerLink
}: IHMICustomizationDrawer) => {
  const { customizationId } = useParams();
  const navigate = useNavigate();

  const { document, isLoading, isError, mutate } = useDocument(customizationId);

  const handleClose = () => {
    navigate(closeDrawerLink);
  };

  const renderContent = () => {
    if (isError) {
      return <DataError onRefresh={() => mutate(undefined, true)} />;
    }

    if (isLoading) {
      return (
        <div className="-mt-[59px]">
          <div className="mb-[31px]">
            <LoadingSkeleton className="max-w-72" height={32} />
          </div>
          <div className="-mt-[23px]">
            <LoadingSkeleton className="max-w-48" height={18} />
          </div>
        </div>
      );
    }

    if (document === undefined) {
      return null;
    }

    return (
      <div className="-mt-[23px]">
        <Content document={document} />
      </div>
    );
  };

  return (
    <Drawer
      title={document?.name}
      isOpen={customizationId !== undefined}
      onClose={handleClose}
    >
      {renderContent()}
    </Drawer>
  );
};

const Content = ({ document }: { document: HmiDocument }) => {
  const { t, t_generic } = usePortalTranslation('hmi');

  let description = '';

  if (document?.documentType === DocumentType.CUSTOMER_PREFERENCES) {
    description = t_generic('labels.entity_preferences', {
      titleize: true,
      entity: t_generic('literals.customer')
    });
  } else {
    description = t_generic('labels.entity_settings', {
      titleize: true,
      entity: t_generic('literals.technical')
    });
  }

  const tableTitle = t('labels.applied_to_chargers');

  return (
    <>
      <Typography className="mb-6">{description}</Typography>
      <HMICustomizationDrawerActions
        onDelete={() => null}
        editLink={getEditLink(document.documentId, document.documentType)}
      />
      <hr className="mb-4 mt-6 h-px bg-[#EBEBEB]" />
      <Typography className="mb-4" variant="smallTitle">
        {tableTitle}
      </Typography>
      <div className="max-h-[400px] overflow-y-auto">
        <HMICustomizationDrawerChargersTable
          serialNumbers={document.serialNumbers}
        />
      </div>
    </>
  );
};
