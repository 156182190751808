import { KeyedMutator } from 'swr';

import { Card, DataError, Typography } from '@destination/components';

import { HmiDocument } from '@omnis-hmi-types';

export interface IHMICustomizationEditError {
  pageTitle: string;
  mutate: KeyedMutator<HmiDocument>;
}

export const HMICustomizationEditError = ({
  pageTitle,
  mutate
}: IHMICustomizationEditError) => {
  return (
    <>
      <Typography className="mb-6" variant="pageTitle">
        {pageTitle}
      </Typography>
      <div className="max-w-lg">
        <Card>
          <div className="flex h-[145px] items-center">
            <DataError onRefresh={() => mutate(undefined, true)} />
          </div>
        </Card>
      </div>
    </>
  );
};
