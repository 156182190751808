import { usePortalTranslation } from '@hooks/use-portal-translation';

import FolderIcon from '@icons/folder_24.svg';

import { Card, Input, Typography } from '@destination/components';

interface IHMICustomizationFileDetailsCard {
  name: string;
  filename: string;
}

export const HMICustomizationFileDetailsCard = ({
  name,
  filename
}: IHMICustomizationFileDetailsCard) => {
  const { t } = usePortalTranslation('hmi');
  return (
    <Card>
      <Typography variant="smallTitle" className="mb-[35px]">
        {t('labels.name_of_the_theme')}
      </Typography>
      <div className="mb-7 max-w-[342px]">
        <Input
          id="name"
          label="Name"
          value={name}
          onChange={() => undefined}
          disabled
        />
      </div>
      <div className="ml-3 flex items-center gap-2">
        <FolderIcon className="text-[#BABABA]" />
        <Typography className="text-[#BABABA]">{filename + '.json'}</Typography>
      </div>
    </Card>
  );
};
