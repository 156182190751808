import { usePortalTranslation } from '@hooks/use-portal-translation';
import { Link } from 'react-router-dom';

import DeleteIcon from '@icons/delete_16.svg';
import EditIcon from '@icons/edit_16.svg';

import { Button } from '@destination/components';

interface IHMICustomizationDrawerActions {
  editLink: string;
  onDelete: () => void;
}

export const HMICustomizationDrawerActions = ({
  editLink,
  onDelete
}: IHMICustomizationDrawerActions) => {
  const { t } = usePortalTranslation();

  const deleteLabel = t('buttons.delete', {
    entity: '',
    titleize: true
  });
  const editLabel = t('buttons.edit', {
    entity: '',
    titleize: true
  });

  return (
    <div className="flex justify-center gap-6">
      <Button
        className="hidden"
        variant="ghost"
        icon={<DeleteIcon />}
        size="big"
        onClick={onDelete}
      >
        {deleteLabel}
      </Button>
      <Link to={editLink}>
        <Button variant="ghost" icon={<EditIcon />} size="big">
          {editLabel}
        </Button>
      </Link>
    </div>
  );
};
