import { UploadProvider, useUploadContext } from '@context/hmi/UploadContext';

import { Typography } from '@destination/components';

import { DocumentType } from '@omnis-hmi-types';

import { HMICustomizationApplyToChargersCard } from '../chargers/HMICustomizationApplyToChargersCard';
import { HMICustomizationFileInput } from './HMICustomizationFileInput';
import { HMICustomizationUploadActions } from './HMICustomizationUploadActions';

interface IHMICustomizationUpload {
  documentType: DocumentType;
  pageTitle: string;
  pageDescription: string;
  cardTitle: string;
  errorNotification: string;
  successNotification: string;
}

export const HMICustomizationUpload = ({
  documentType,
  pageTitle,
  pageDescription,
  cardTitle,
  errorNotification,
  successNotification
}: IHMICustomizationUpload) => {
  return (
    <UploadProvider>
      <div className="mb-12 max-w-lg">
        <Typography variant="pageTitle" className="mb-2">
          {pageTitle}
        </Typography>
        <Typography className="mb-4">{pageDescription}</Typography>
        <div className="mb-6">
          <HMICustomizationFileInput cardTitle={cardTitle} />
        </div>
        <HMICustomizationApplyTo />
      </div>
      <HMICustomizationUploadActions
        documentType={documentType}
        errorNotification={errorNotification}
        successNotification={successNotification}
      />
    </UploadProvider>
  );
};

const HMICustomizationApplyTo = () => {
  const { file, selected, setSelected, isProcessing } = useUploadContext();

  return (
    <HMICustomizationApplyToChargersCard
      isFile={Boolean(file)}
      isDisabled={isProcessing}
      selected={selected}
      setSelected={setSelected}
    />
  );
};
