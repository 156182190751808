import { useEffect, useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import useDocumentsTemplates from '@api/documents/use-documents-templates';
import DownloadIcon from '@icons/download_16.svg';

import { Button } from '@destination/components';

import { DocumentType } from '@omnis-hmi-types';

interface IHMICustomizationDownloadTemplate {
  documentType: DocumentType;
}

export const HMICustomizationDownloadTemplate = ({
  documentType
}: IHMICustomizationDownloadTemplate) => {
  const { t } = usePortalTranslation();

  const [isDownloadStarted, setIsDownloadStarted] = useState(false);

  const { template, isLoading, isError } = useDocumentsTemplates({
    documentType,
    isDownloadStarted
  });

  useEffect(() => {
    if (template) {
      const blob = new Blob([JSON.stringify(template, null, 2)], {
        type: 'application/json'
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${documentType}-template.json`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      setIsDownloadStarted(false);
    }
  }, [template, documentType]);

  useEffect(() => {
    if (isError) {
      setIsDownloadStarted(false);
    }
  }, [isError]);

  const handleClick = () => {
    setIsDownloadStarted(true);
  };

  const label = t('buttons.download', {
    entity: t('literals.template'),
    titleize: true
  });

  return (
    <Button
      variant="ghost"
      data-testid="download-button"
      isLoading={isLoading}
      icon={<DownloadIcon />}
      onClick={handleClick}
    >
      {label}
    </Button>
  );
};
