import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import patchDocument from '@api/documents/patch-document';

import { Typography, useAlert } from '@destination/components';

import { HmiDocument } from '@omnis-hmi-types';

import { HMICustomizationApplyToChargersCard } from '../chargers/HMICustomizationApplyToChargersCard';
import { HMICustomizationEditActions } from './HMICustomizationEditActions';
import { HMICustomizationFileDetailsCard } from './HMICustomizationFileDetailsCard';

interface IHMICustomizationEdit {
  hmiDocument: HmiDocument;
  pageTitle: string;
  errorNotification: string;
  successNotification: string;
}

export const HMICustomizationEdit = ({
  hmiDocument,
  pageTitle,
  errorNotification,
  successNotification
}: IHMICustomizationEdit) => {
  const [selected, setSelected] = useState(new Set<string>());
  const [isProcessing, setIsProcessing] = useState(false);

  const { notify, notifyError } = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    const initialSelected = new Set(
      hmiDocument.serialNumbers.map(serialNumber => serialNumber)
    );
    setSelected(initialSelected);
  }, [hmiDocument.serialNumbers]);

  const handleSave = async () => {
    setIsProcessing(true);
    const { error } = await patchDocument(
      hmiDocument.documentId,
      Array.from(selected)
    );

    if (error) {
      notifyError({
        header: errorNotification,
        'data-testid': 'upload-error-alert'
      });
    } else {
      notify({
        variant: 'success',
        header: successNotification,
        'data-testid': 'upload-success-alert'
      });
      navigate('/hmi-settings/charger-customization');
    }

    setIsProcessing(false);
  };

  const { name = '', filename = '' } = hmiDocument;

  return (
    <>
      <Typography className="mb-6" variant="pageTitle">
        {pageTitle}
      </Typography>
      <div className="mb-12 flex max-w-lg flex-col gap-6">
        <HMICustomizationFileDetailsCard name={name} filename={filename} />
        <HMICustomizationApplyToChargersCard
          isFile={true}
          isDisabled={isProcessing}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
      <HMICustomizationEditActions
        isDisabled={isProcessing}
        onSave={handleSave}
      />
    </>
  );
};
