// CurrentDeliveredFallback.tsx
import { useMemo } from 'react';

import { getTime } from 'date-fns';
import { useParams } from 'react-router-dom';

import useSiteTelemetryCurrentDelivered from '@api/sites/use-site-telemetry-current-delivered';
import { useInsightsContext } from '@context/InsightsContext';
import { YAxisTickProvider } from '@context/YAxisTickContext';

import {
  CurrentDeliveredData,
  CurrentDeliveredGraph
} from '../insights/CurrentDeliveredGraph';

export const SiteOverviewFallback = () => {
  const { id } = useParams() as { id: string };
  const { range } = useInsightsContext();
  const { data: alternativeData, isLoading: isLoadingSiteTelemetryCurrent } =
    useSiteTelemetryCurrentDelivered(
      id,
      range[0].toISOString(),
      range[1].toISOString()
    );

  const currentDelivered: CurrentDeliveredData = useMemo(() => {
    if (alternativeData) {
      return alternativeData.map(item => ({
        ...item,
        timestamp: getTime(item.timestamp)
      }));
    }
    return [];
  }, [alternativeData]);

  return (
    <YAxisTickProvider>
      <CurrentDeliveredGraph
        data={isLoadingSiteTelemetryCurrent ? [] : currentDelivered}
        isLoading={isLoadingSiteTelemetryCurrent}
      />
    </YAxisTickProvider>
  );
};
