import {
  Checkbox,
  HighlightedText,
  TableDataCell
} from '@destination/components';

export interface IHMICustomizationChargersTableRow {
  isChecked: boolean;
  isDisabled: boolean;
  searchTerm: string;
  name: string;
  serial: string;
  model: string;
  onToggle: () => void;
}

export const HMICustomizationChargersTableRow = ({
  isChecked,
  isDisabled,
  searchTerm,
  name,
  serial,
  model,
  onToggle
}: IHMICustomizationChargersTableRow) => {
  return (
    <>
      <TableDataCell
        className={`border-b-[1px] border-[#DBDBDB] !pl-3 ${isChecked && 'font-bold'} flex items-center gap-2`}
        title={name}
      >
        <div className="inline-flex items-center">
          <Checkbox
            label={undefined}
            checked={isChecked}
            disabled={isDisabled}
            onChange={onToggle}
          />
        </div>
        <button
          className="truncate [&>span]:!py-0"
          disabled={isDisabled}
          onClick={onToggle}
        >
          <HighlightedText text={name} highlighted={searchTerm} />
        </button>
      </TableDataCell>
      <TableDataCell
        className="truncate border-b-[1px] border-[#DBDBDB]"
        title={serial}
      >
        <HighlightedText text={serial} highlighted={searchTerm} />
      </TableDataCell>
      <TableDataCell
        className="truncate border-b-[1px] border-[#DBDBDB]"
        title={model}
      >
        {model}
      </TableDataCell>
    </>
  );
};
