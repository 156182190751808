import {
  accessManagement,
  account,
  chargers,
  devices,
  gateways,
  generic,
  gridMeters,
  hmi,
  legacy,
  sites,
  siteSettings
} from '@locales/en';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import {
  formatConjunctiveList,
  formatDisjunctiveList,
  titleize
} from '@utils/i18n-utils';

// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
export default i18n
  .use(initReactI18next)
  .init({
    lng: 'en',
    resources: {
      en: {
        'DS.Destination.ServicePortal': { ...legacy }, // this namespace should be deleted
        accessManagement: { ...accessManagement },
        account: { ...account },
        chargers: { ...chargers },
        gateways: { ...gateways },
        generic: { ...generic },
        gridMeters: { ...gridMeters },
        hmi: { ...hmi },
        siteSettings: { ...siteSettings },
        sites: { ...sites },
        devices: { ...devices }
      }
    },
    defaultNS: 'DS.Destination.ServicePortal', // should be deleted
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })
  .then(() => {
    i18n.services?.formatter?.add('disjunction', formatDisjunctiveList);
    i18n.services?.formatter?.add('conjunction', formatConjunctiveList);
    i18n.services?.formatter?.add('titleize', titleize);
  });
