import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useSiteGridMeterTelemetry from '@api/sites/use-site-grid-meter-telemetry';
import { InsightsProvider } from '@context/InsightsContext';

import {
  Card,
  DataError,
  LoadingSkeleton,
  Typography
} from '@destination/components';

import { SiteOverviewChart } from './SiteOverviewChart';
import { SiteOverviewFallback } from './SiteOverviewFallback';

export const SiteOverview = () => {
  const { id } = useParams() as { id: string };
  const { t } = useTranslation(undefined, {
    keyPrefix: 'sites.site.site_overview.site_overview'
  });
  const { data, isLoading, isError, mutate } = useSiteGridMeterTelemetry(id);

  const renderContent = () => {
    if (isError) {
      return (
        <div className="flex h-full w-full items-center">
          <DataError onRefresh={() => mutate(undefined, true)} />
        </div>
      );
    }

    if (isLoading) {
      return (
        <LoadingSkeleton
          isDarker
          className="!h-full rounded-[10px]"
          height={0}
        />
      );
    }

    if (
      data === undefined ||
      ((data?.current || []).length === 0 &&
        (data?.maxCurrent || []).length === 0)
    ) {
      return (
        <InsightsProvider>
          <Card className="h-full">
            <SiteOverviewFallback />
          </Card>
        </InsightsProvider>
      );
    }

    return (
      <Card className="h-full">
        <SiteOverviewChart data={data} />
      </Card>
    );
  };

  return (
    <div
      className="flex h-full w-full flex-col"
      data-testid="energy-delivered-graph"
    >
      <div className="mb-3 flex flex-col gap-1">
        <Typography variant="sectionTitle">{t('title')}</Typography>
        <Typography variant="description">{t('description')}</Typography>
      </div>
      <div className="w-full flex-1">{renderContent()}</div>
    </div>
  );
};
